const brandsData = [
  "./images/brands/png/Acumen-International.png",
  "./images/brands/png/Allen-Bradley.png",
  "./images/brands/png/Allison-Transmission.png",
  "./images/brands/png/Amphenol.png",
  "./images/brands/png/ashcroft.png",
  "./images/brands/png/atlas-copco.png",
  "./images/brands/png/auma.png",
  "./images/brands/png/baldor.png",
  "./images/brands/png/Bendix.png",
  "./images/brands/png/Billy-Pugh.png",
  "./images/brands/png/Braden.png",
  "./images/brands/png/brother.png",
  "./images/brands/png/CAD.png",
  "./images/brands/png/cam-tech.png",
  "./images/brands/png/castrol.png",
  "./images/brands/png/Cavins.png",
  "./images/brands/png/Crosby.png",
  "./images/brands/png/danfoss.png",
  "./images/brands/png/den-con.png",
  "./images/brands/png/Detroit.png",
  "./images/brands/png/Deutz.png",
  "./images/brands/png/Dixon.png",
  "./images/brands/png/dodge.png",
  "./images/brands/png/Donaldson.png",
  "./images/brands/png/Drager.png",
  "./images/brands/png/eaton.png",
  "./images/brands/png/electrolux.png",
  "./images/brands/png/emerson.png",
  "./images/brands/png/Enerpac.png",
  "./images/brands/png/esab.png",
  "./images/brands/png/fag.png",
  "./images/brands/png/fisher.png",
  "./images/brands/png/Flender.png",
  "./images/brands/png/FMC.png",
  "./images/brands/png/Forum.png",
  "./images/brands/png/gardner-denver.png",
  "./images/brands/png/G-E.png",
  "./images/brands/png/Gearench.png",
  "./images/brands/png/Global-Manufacturing.png",
  "./images/brands/png/good-year.png",
  "./images/brands/png/grohe.png",
  "./images/brands/png/grundfos.png",
  "./images/brands/png/gummi-USA.png",
  "./images/brands/png/hima.png",
  "./images/brands/png/hobart.png",
  "./images/brands/png/HP.png",
  "./images/brands/png/hubbel.png",
  "./images/brands/png/Hydril.png",
  "./images/brands/png/hyundai.png",
  "./images/brands/png/IMI.png",
  "./images/brands/png/ina.png",
  "./images/brands/png/ingersoll-rand.png",
  "./images/brands/png/jet-lube.png",
  "./images/brands/png/John-Deere.png",
  "./images/brands/png/karcher.png",
  "./images/brands/png/Kemper.png",
  "./images/brands/png/KSB.png",
  "./images/brands/png/Lang.png",
  "./images/brands/png/Lewa.png",
  "./images/brands/png/Liebherr.png",
  "./images/brands/png/lincoln-electric.png",
  "./images/brands/png/MAN.png",
  "./images/brands/png/manitou.png",
  "./images/brands/png/Mattco.png",
  "./images/brands/png/Maytag.png",
  "./images/brands/png/McCoy.png",
  "./images/brands/png/Mercedes.png",
  "./images/brands/png/mersen.png",
  "./images/brands/png/mettler-toledo.png",
  "./images/brands/png/michelin.png",
  "./images/brands/png/milton-roy.png",
  "./images/brands/png/mitsubishi.png",
  "./images/brands/png/mobil.png",
  "./images/brands/png/MSA.png",
  "./images/brands/png/MTU.png",
  "./images/brands/png/nissan.png",
  "./images/brands/png/NOV.png",
  "./images/brands/png/odrill-MCM.png",
  "./images/brands/png/offshore-handling-systems.png",
  "./images/brands/png/Oteco.png",
  "./images/brands/png/Panolin.png",
  "./images/brands/png/parker.png",
  "./images/brands/png/pentair.png",
  "./images/brands/png/perkins.png",
  "./images/brands/png/premium.png",
  "./images/brands/png/probe.png",
  "./images/brands/png/rema-tiptop.png",
  "./images/brands/png/renault.png",
  "./images/brands/png/resinex.png",
  "./images/brands/png/Rexnord.png",
  "./images/brands/png/Rexroth.png",
  "./images/brands/png/Rubicon.png",
  "./images/brands/png/Safeguard.png",
  "./images/brands/png/samsung.png",
  "./images/brands/png/sandvik.png",
  "./images/brands/png/shell.png",
  "./images/brands/png/siemens.png",
  "./images/brands/png/skf.png",
  "./images/brands/png/Stewart-Stevenson.png",
  "./images/brands/png/sti-screens.png",
  "./images/brands/png/Sullair.png",
  "./images/brands/png/terex.png",
  "./images/brands/png/total.png",
  "./images/brands/png/toyota.png",
  "./images/brands/png/vlamboog.png",
  "./images/brands/png/voith.png",
  "./images/brands/png/volvo.png",
  "./images/brands/png/weatherford.png",
  "./images/brands/png/Westco.png",
  "./images/brands/png/xylem.png",
];

export default brandsData;
